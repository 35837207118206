<template>
  <div class="vh-100">
    <div
      class="h-100 d-flex flex-column justify-content-enter align-items-center p-5"
    >
      <div class="w-100">
        <div class="row">
          <div class="col d-flex justify-content-between">
            <b-input
              class="h-100"
              v-model="form.visit_code"
              placeholder="Please enter your visitation code here..."
            ></b-input>
            <b-button variant="primary" @click="handleNavigate">
              PROCEED
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      pageTitle: "VISITATION CODE",

      // invite from host
      form: {
        visit_code: null
      },

      inviteList: []
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getPreference() {
      return this.$store.getters["vmsPreferences2Module/getPreferences"];
    },
    getSettings() {
      return this.$store.getters["vmsSettingsModule/getSettings"];
    }
  },
  mounted: function () {
    this.getInviteAll();
    this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
    this.$store.dispatch("vmsSettingsModule/getSettings");
  },
  methods: {
    getInviteAll() {
      let API_URL = this.getAPIServerURL + "/api/visitorservice/invite/all/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let validUrl = "";
          let result = data.result;
          if (Object.keys(result).length !== 0) {
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined && Array.isArray(result[key])) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }

            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl])
            ) {
              for (let key2 in result[validUrl]) {
                this.fetchVisit(
                  result[validUrl][key2].visit_id,
                  result[validUrl][key2].invite_code
                );
              }
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    fetchVisit(visit_id, invite_code) {
      let API_URL =
        this.getAPIServerURL +
        "/api/visitorservice/visit/?visit_id=" +
        visit_id;
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let validUrl2 = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key3 in result) {
              if (result[key3] != undefined) {
                validUrl2 = key3;
                break outer_loop;
              }
            }
          }

          if (result[validUrl2] != undefined) {
            result[validUrl2]["invite_code"] = invite_code;
            this.inviteList.push(result[validUrl2]);
          }
          console.log("this.inviteList", this.inviteList);
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    handleNavigate() {
      let isValidCode = false;
      let isCheckIn = false;
      if (
        !this.getPreference.additional_metadata.vms_host_invite_check_in_auto
      ) {
        for (let key4 in this.inviteList) {
          if (this.form.visit_code == this.inviteList[key4].visit_code) {
            isValidCode = true;
            if (
              this.inviteList[key4].additional_metadata.check_in_time ==
              undefined
            ) {
              isCheckIn = false;
              this.$router.push({
                name: "kiosk2-invite",
                params: {
                  invite_code: this.inviteList[key4].invite_code,
                  parent_url: "kiosk2"
                }
              });
            } else {
              isCheckIn = false;
            }
          }
        }
      }

      if (
        this.getPreference.additional_metadata.vms_host_invite_check_in_auto
      ) {
        for (let key5 in this.inviteList) {
          if (
            this.form.visit_code == this.inviteList[key5].visit_code &&
            this.inviteList[key5].additional_metadata.check_in_time != undefined
          ) {
            isValidCode = true;
            isCheckIn = true;
          } else {
            isValidCode = false;
          }
        }
      }

      if (!isValidCode) {
        isValidCode = false;
        isCheckIn = false;
        this.$bvModal.msgBoxOk("Please enter the valid visitation code", {
          centered: true
        });
        return false;
      } else if (isValidCode && !isCheckIn) {
        isValidCode = false;
        isCheckIn = false;
        this.$bvModal.msgBoxOk(
          "The visitation code is accessed to check in. Please check email for the info",
          {
            centered: true
          }
        );
        return false;
      } else if (isValidCode && isCheckIn) {
        isValidCode = false;
        isCheckIn = false;
        this.$bvModal.msgBoxOk(
          "The visitation code is accessed to check in. Please check email for the info",
          {
            centered: true
          }
        );
        return false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
